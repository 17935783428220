import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OfferList.css";
import OfferItem from "./OfferItem";

const rankedOfferList = [
    {
        "id": 6939,
        "title": "Get USD 5.00 Cashback",
        "images": [
            "https://catalyst-cdn.pulseid.com/ApbcROresH/e772adba-5812-4595-84c1-387725b59cd2.png"
        ],
        "merchantId": 307,
        "merchantName": "Auto Mart",
        "profileImageUrl": "https://test-cdn.pulseid.com/5phrb2W3xU/78774a82-a8ba-4723-9f18-1c0990525a47.png",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
    {
        "id": 6941,
        "title": "Get USD 5.00 Cashback",
        "images": ["https://catalyst-cdn.pulseid.com/z6MRgUOcnP/1e0dc461-2a9a-44ca-999c-92f8cd78754c.png"],
        "merchantId": 308,
        "merchantName": "Auto Supermarket",
        "profileImageUrl": "https://test-cdn.pulseid.com/SEmiWE2cYD/1ef184dd-496b-4bf3-87a3-2da89ff20e81.png",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
    {
        "id": 7464,
        "title": "Get SGD 35.00 Cashback",
        "images": [
            "https://test-cdn.pulseid.com/1DZV6Fl9YX/11b82733-7a71-4dbd-8c63-e76df258fc12.jpg"
        ],
        "merchantId": 306,
        "merchantName": "Booktopia Australia",
        "profileImageUrl": "https://test-cdn.pulseid.com/BjXr3G2XUQ/d5173870-3a2d-46b4-925c-8ec85d519bdf.png",
        "description": "Get SGD&nbsp;35.00 Cashback"
    },
    {
        "id": 6937,
        "title": "Get 2% Cashback for all customers",
        "images": [
            "https://catalyst-cdn.pulseid.com/6ivKc36BUu/789d214a-762f-43a5-8c49-a700e3c0b653.png"
        ],
        "merchantId": 305,
        "merchantName": "Target",
        "profileImageUrl": "https://test-cdn.pulseid.com/GQRqDxYVi8/beadc431-53c5-4a82-8452-2826a16cfa6c.jpeg",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
    {
        "id": 240,
        "title": "Earn 7% Reward",
        "images": [
            "https://catalyst-cdn.pulseid.com/k2R2aTuus7/717f0e56-babf-4a6d-8589-b213564337bd.png"
        ],
        "merchantId": 306,
        "merchantName": "Nike",
        "profileImageUrl": "https://test-cdn.pulseid.com/BjXr3G2XUQ/d5173870-3a2d-46b4-925c-8ec85d519bdf.png",
        "description": "Get SGD&nbsp;35.00 Cashback"
    },
    {
        "id": 243,
        "title": "Earn 1.5% Reward",
        "images": [
            "https://catalyst-cdn.pulseid.com/Kw2JzTrg5V/76dfdb5b-69d8-4ce0-b07c-e10b79f231bd.png"
        ],
        "merchantId": 305,
        "merchantName": "Watsons Singapore",
        "profileImageUrl": "https://test-cdn.pulseid.com/GQRqDxYVi8/beadc431-53c5-4a82-8452-2826a16cfa6c.jpeg",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
    {
        "id": 6939,
        "title": "Get USD 5.00 Cashback",
        "images": [
            "https://catalyst-cdn.pulseid.com/ApbcROresH/e772adba-5812-4595-84c1-387725b59cd2.png"
        ],
        "merchantId": 307,
        "merchantName": "Auto Mart",
        "profileImageUrl": "https://test-cdn.pulseid.com/5phrb2W3xU/78774a82-a8ba-4723-9f18-1c0990525a47.png",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },

    {
        "id": 69391,
        "title": "Get USD 5.00 Cashback",
        "images": [
            "https://catalyst-cdn.pulseid.com/ApbcROresH/e772adba-5812-4595-84c1-387725b59cd2.png"
        ],
        "merchantId": 307,
        "merchantName": "Auto Mart",
        "profileImageUrl": "https://test-cdn.pulseid.com/5phrb2W3xU/78774a82-a8ba-4723-9f18-1c0990525a47.png",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
    {
        "id": 69413,
        "title": "Get USD 5.00 Cashback",
        "images": ["https://catalyst-cdn.pulseid.com/z6MRgUOcnP/1e0dc461-2a9a-44ca-999c-92f8cd78754c.png"],
        "merchantId": 308,
        "merchantName": "Auto Supermarket",
        "profileImageUrl": "https://test-cdn.pulseid.com/SEmiWE2cYD/1ef184dd-496b-4bf3-87a3-2da89ff20e81.png",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
    {
        "id": 74643,
        "title": "Get SGD 35.00 Cashback",
        "images": [
            "https://test-cdn.pulseid.com/1DZV6Fl9YX/11b82733-7a71-4dbd-8c63-e76df258fc12.jpg"
        ],
        "merchantId": 306,
        "merchantName": "Booktopia Australia",
        "profileImageUrl": "https://test-cdn.pulseid.com/BjXr3G2XUQ/d5173870-3a2d-46b4-925c-8ec85d519bdf.png",
        "description": "Get SGD&nbsp;35.00 Cashback"
    },
    {
        "id": 69373,
        "title": "Get 2% Cashback for all customers",
        "images": [
            "https://catalyst-cdn.pulseid.com/6ivKc36BUu/789d214a-762f-43a5-8c49-a700e3c0b653.png"
        ],
        "merchantId": 305,
        "merchantName": "Target",
        "profileImageUrl": "https://test-cdn.pulseid.com/GQRqDxYVi8/beadc431-53c5-4a82-8452-2826a16cfa6c.jpeg",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
    {
        "id": 2403,
        "title": "Earn 7% Reward",
        "images": [
            "https://catalyst-cdn.pulseid.com/k2R2aTuus7/717f0e56-babf-4a6d-8589-b213564337bd.png"
        ],
        "merchantId": 306,
        "merchantName": "Nike",
        "profileImageUrl": "https://test-cdn.pulseid.com/BjXr3G2XUQ/d5173870-3a2d-46b4-925c-8ec85d519bdf.png",
        "description": "Get SGD&nbsp;35.00 Cashback"
    },
    {
        "id": 2433,
        "title": "Earn 1.5% Reward",
        "images": [
            "https://catalyst-cdn.pulseid.com/Kw2JzTrg5V/76dfdb5b-69d8-4ce0-b07c-e10b79f231bd.png"
        ],
        "merchantId": 305,
        "merchantName": "Watsons Singapore",
        "profileImageUrl": "https://test-cdn.pulseid.com/GQRqDxYVi8/beadc431-53c5-4a82-8452-2826a16cfa6c.jpeg",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
    {
        "id": 69393,
        "title": "Get USD 5.00 Cashback",
        "images": [
            "https://catalyst-cdn.pulseid.com/ApbcROresH/e772adba-5812-4595-84c1-387725b59cd2.png"
        ],
        "merchantId": 307,
        "merchantName": "Auto Mart",
        "profileImageUrl": "https://test-cdn.pulseid.com/5phrb2W3xU/78774a82-a8ba-4723-9f18-1c0990525a47.png",
        "description": "Put yourself in a customers's shoes. Some information may seem obvious, but be detailed so customers can know more about the what you offer for them."
    },
]

const OfferList = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
  //  const [bigItems, setBigItems] = useState(0);

    useEffect(() => {
        const url = 'https://test-netlify.pulseid.com/aws-personalisation?externalUserId=DILEEMAEXTUSERL003';

        const headers = {
            "x-api-key": '03111dadd30c310c344a007c2a3ad4999c1de2d4974b432928a7591766842b64f615dacc2af4cab1710305e9cdb3a4179bde3588cffa879719aa26b707e6f21e',
            "x-api-secret": '55347a2fa1bbac0a40e08275f39d451ef607ef086bedae2cacace8f57b4a82fa899cf701ff2f0848fd96fa08f2264f0d9f98513854d91077e14dee101d8bdd5f'
        }

        axios.get(url, { headers })
          .then(response => {
            console.log('responseeeeeeee: ', response.data)
            console.log('ranked offerssssssss: ', response.data.rankedOfferList)
            const data = response.data ? response.data.rankedOfferList : null
            setOffers(data);
            setLoading(false);
          })
          .catch(error => {
            console.error(error);
            setLoading(false);
          });
    }, []);

    // useEffect(async () => {
    //     const url = 'https://test-netlify.pulseid.com/aws-personalisation?externalUserId=DILEEMAEXTUSERL003';

    //     const headers = {
    //         "x-api-key": '03111dadd30c310c344a007c2a3ad4999c1de2d4974b432928a7591766842b64f615dacc2af4cab1710305e9cdb3a4179bde3588cffa879719aa26b707e6f21e',
    //         "x-api-secret": '55347a2fa1bbac0a40e08275f39d451ef607ef086bedae2cacace8f57b4a82fa899cf701ff2f0848fd96fa08f2264f0d9f98513854d91077e14dee101d8bdd5f'
    //     }
    
    //     try {
    //       // Make an API call and assign the response to a variable
    //       const response = await axios.get(url, { headers });
    //       // Use the response data to update the state
    //       setOffers(response.data.rankedOfferList);
    //     } catch (error) {
    //       // Handle errors
    //       console.error(error);
    //     }
    //   }, []);

    // useEffect(() => {
    //     setBigItems(Math.floor(offers.length / 5));
    // }, [offers]);

    console.log('offer stateeeeeeeee: ', offers)
    

    if (loading) {
        // Return a loading indicator
        return <div>Loading...</div>;
      } else {
        return (
            <div>
                
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td className="small-1">
                                <OfferItem offer={offers[0]} key={offers[0].id} big={false} />
                            </td>
                            <td className="big" rowSpan={2} colSpan={2}>
                            <OfferItem offer={offers[1]} key={offers[1].id} big={true} />
                            </td>
                        </tr>
                        <tr>
                            <td className="small-1">
                            <OfferItem offer={offers[2]} key={offers[2].id} big={false} />
                            </td>
                        </tr>
                        <tr>
                            <td className="small-1"><OfferItem offer={offers[3]} key={offers[3].id} big={false} /></td>
                            <td className="small-1"><OfferItem offer={offers[4]} key={offers[4].id} big={false} /></td>
                            <td className="small-1"><OfferItem offer={offers[5]} key={offers[5].id} big={false} /></td>
                        </tr>
                        <tr>
                            <td className="full" colSpan={3}><OfferItem offer={offers[6]} key={offers[6].id} big={false} /></td>
                        </tr>
                        <tr>
                            <td className="small-2" colSpan={2}><OfferItem offer={offers[7]} key={offers[7].id} big={false} /></td>
                            <td className="small-1"><OfferItem offer={offers[8]} key={offers[8].id} big={false} /></td>
                        </tr>
                    </tbody>
                </table>
    
                {/* <div className="offer-list">
                    {offers.map((offer, index) => (
                        <OfferItem offer={offer} key={offer.id} big={index < bigItems} />
                    ))}
                </div> */}
            </div>
        );
      }



};

export default OfferList;
