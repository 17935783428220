import React from "react";
import "./NavBar.css";

const NavBar = () => {
  return (
    <div className="nav-bar">
      <h1>Offer List</h1>
    </div>
  );
};

export default NavBar;
