import React from "react";
import "./OfferItem.css";

const OfferItem = ({ offer, big }) => {
  return (
    <div className={big ? "offer-item-big" : "offer-item"}>
      <span>
        <img className={big ? "offer-img-big" : "offer-img"} src={offer.images[0]} alt={offer.title} />
        <p className="get-offer">+ GET</p>
      </span>
      <h4>{offer.title}</h4>
      <p>{offer.merchantName}</p>
    </div>
  );
};

export default OfferItem;
