// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile {
    display: inline-block;
}

.small-1 {
    width: 33%;
    height: 75px;
}

.small-2 {
    width: 66%;
    height: 75px;

}

.big {
    width: 66%;
    height: 150px;

}

.full {
    width: 100%;
    height: 75px;


}








.offer-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px;
    padding: 15px;
}

selector {
    box-shadow: offset-x offset-y blur-radius spread-radius color inset;
}

.offer-list {
    box-shadow: 5px 5px 10px 0px black;
}

selector {
    text-shadow: offset-x offset-y blur-radius color;
}

.offer-list {
    box-shadow: inset 0px 0px 20px 10px rgb(192, 192, 209), 10px 10px 15px 5px rgb(192, 192, 209);
}

/* Use a media query to change the grid layout for smaller screens */
@media (max-width: 600px) {
    .offer-list {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
}

@media (max-width: 600px) {
    .offer-list {
        /* Use one column for smaller screens */
        grid-template-columns: 1fr;
        /* Use auto for the rows by default */
        grid-template-rows: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/OfferList.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;;AAEhB;;AAEA;IACI,UAAU;IACV,aAAa;;AAEjB;;AAEA;IACI,WAAW;IACX,YAAY;;;AAGhB;;;;;;;;;AASA;IACI,aAAa;IACb,2DAA2D;IAC3D,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,mEAAmE;AACvE;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,6FAA6F;AACjG;;AAEA,oEAAoE;AACpE;IACI;QACI,0BAA0B;QAC1B,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,uCAAuC;QACvC,0BAA0B;QAC1B,qCAAqC;QACrC,wBAAwB;IAC5B;AACJ","sourcesContent":[".tile {\n    display: inline-block;\n}\n\n.small-1 {\n    width: 33%;\n    height: 75px;\n}\n\n.small-2 {\n    width: 66%;\n    height: 75px;\n\n}\n\n.big {\n    width: 66%;\n    height: 150px;\n\n}\n\n.full {\n    width: 100%;\n    height: 75px;\n\n\n}\n\n\n\n\n\n\n\n\n.offer-list {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n    grid-gap: 10px;\n    padding: 15px;\n}\n\nselector {\n    box-shadow: offset-x offset-y blur-radius spread-radius color inset;\n}\n\n.offer-list {\n    box-shadow: 5px 5px 10px 0px black;\n}\n\nselector {\n    text-shadow: offset-x offset-y blur-radius color;\n}\n\n.offer-list {\n    box-shadow: inset 0px 0px 20px 10px rgb(192, 192, 209), 10px 10px 15px 5px rgb(192, 192, 209);\n}\n\n/* Use a media query to change the grid layout for smaller screens */\n@media (max-width: 600px) {\n    .offer-list {\n        grid-template-columns: 1fr;\n        grid-template-rows: auto;\n    }\n}\n\n@media (max-width: 600px) {\n    .offer-list {\n        /* Use one column for smaller screens */\n        grid-template-columns: 1fr;\n        /* Use auto for the rows by default */\n        grid-template-rows: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
