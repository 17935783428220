// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3280e;
    color: white;
  }
  
  .nav-bar h1 {
    font-size: 24px;
  }
  
  /* Use a media query to change the flex direction for smaller screens */
  @media (max-width: 600px) {
     .nav-bar {
       /* Use a column direction instead of a row direction */
       flex-direction: column;
     }
  }`, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;;EAEA,uEAAuE;EACvE;KACG;OACE,sDAAsD;OACtD,sBAAsB;KACxB;EACH","sourcesContent":[".nav-bar {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #f3280e;\n    color: white;\n  }\n  \n  .nav-bar h1 {\n    font-size: 24px;\n  }\n  \n  /* Use a media query to change the flex direction for smaller screens */\n  @media (max-width: 600px) {\n     .nav-bar {\n       /* Use a column direction instead of a row direction */\n       flex-direction: column;\n     }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
